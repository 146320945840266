import { useState } from "react";
import ArrowIcon from "@/assets/icons/arrow-white.svg";
import Link from "next/link";
import Arrow from "@/assets/icons/arrow-right.svg";
import getCategory from "@/functions/getCategory";
import CustomSwiperSlide from "@/components/Swiper/CustomSwiperSlide";
import CustomSwiper from "@/components/Swiper/CustomSwiper";
import localizePath from "@/functions/localizePath";
import useLocale from "@/hooks/useLocale";

export default function ShuffleJobs({ hoverColor, titleColor, jobs }) {
  const locale = useLocale();

  const jobsFirstSlider = jobs;

  // Second slider jobs with an index two positions behind
  const jobsSecondSlider = jobs.slice(0, -2);

  const [ref, setRef] = useState();
  const [refSecond, setRefSecond] = useState();

  const handleNext = () => {
    ref?.current?.swiper?.slideNext();
    refSecond?.current?.swiper?.slideNext();
  };

  const handlePrev = () => {
    ref?.current?.swiper?.slidePrev();
    refSecond?.current?.swiper?.slidePrev();
  };

  const getRef = (ref) => {
    setRef(ref);
  };
  const getRefSecond = (ref) => {
    setRefSecond(ref);
  };

  return (
    <div className="relative overflow-hidden z-10 flex gap-[14px] flex-col">
      <div className="flex flex-col-reverse sm:grid grid-cols-3 grid-rows-1 gap-x-2 gap-y-0">
        <div className="sm:mt-0 col-start-1 col-end-3 row-start-1 row-end-2 order-2">
          <CustomSwiper
            className="col-span-2"
            speed={800}
            getRef={getRef}
            view={{ mobile: 1, md: 1, laptop: 1, desktop: 2 }}
            space={14}
            autoplay={false}
          >
            {jobsFirstSlider?.map((item, idx) => (
              <CustomSwiperSlide key={item.id}>
                <div key={item.id} className="h-[21rem] md:h-96">
                  <Link
                    href={localizePath(`/jobs/${item.url_slug}`, locale)}
                    key={idx}
                    className={`bg-grey-100 h-full ${hoverColor ? hoverColor : "hover:bg-green"
                      } group/item text-purple py-4 md:pb-[px] md:pt-10 flex flex-col px-5 md:px-[35px] relative ${`role-${idx}`}`}
                  >
                    <span className="text-2xl sm:text-3xl group-hover/item:underline">
                      {item.title}
                    </span>
                    <span className="text-lg md:text-xl py-4 md:py-[26px]">
                      {item.salary_package}
                    </span>
                    <div className="[&>p]:bg-grey-200 [&>p]:text-base [&>p]:w-max [&>p]:py-3 [&>p]:px-[26px] flex flex-wrap max-w-[350px] [&>p]:mr-[11px] [&>p]:mb-[10px]">
                      {getCategory(item)?.map((type) => (
                        <p key={type.id}>{type.name}</p>
                      ))}
                    </div>
                    <div className="absolute cursor-pointer hidden bottom-0 right-0 bg-purple w-12 h-12 z-10 group-hover/item:flex justify-center items-center">
                      <ArrowIcon className="fill-white" />
                    </div>
                  </Link>
                </div>
              </CustomSwiperSlide>
            ))}
          </CustomSwiper>
        </div>
        <div className="mt-6 lg:mt-0 flex gap-4 items-center justify-start lg:justify-center lg:m-0 order-1">
          <div
            onClick={handlePrev}
            role="button"
            aria-label="Previous"
            className={`bg-grey-700 ${titleColor ? "hover:bg-white" : "hover:bg-purple"
              }  w-12 h-12 sm:w-16 sm:h-16 group/item flex rounded-full md:w-[90px] md:h-[90px] items-center justify-center cursor-pointer`}
          >
            <Arrow
              className={`fill-purple-light rotate-180 w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] ${titleColor
                ? "group-hover/item:fill-purple"
                : "group-hover/item:fill-white"
                }`}
            />
          </div>
          <div
            onClick={handleNext}
            role="button"
            aria-label="Next"
            className={`bg-grey-700 ${titleColor ? "hover:bg-white" : "hover:bg-purple"
              } w-12 h-12 sm:w-16 sm:h-16 group/item flex rounded-full md:w-[90px] md:h-[90px] items-center justify-center cursor-pointer`}
          >
            <Arrow
              className={`fill-purple-light w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] ${titleColor
                ? "group-hover/item:fill-purple"
                : "group-hover/item:fill-white"
                }`}
            />
          </div>
        </div>
      </div>
      {/* <CustomSwiper
        speed={800}
        getRef={getRefSecond}
        view={{ mobile: 1, md: 1, laptop: 1, desktop: 3 }}
        space={14}
        autoplay={false}
      >
        {jobsSecondSlider?.map((item, idx) => (
          <CustomSwiperSlide key={item.id}>
            <div className="h-[21rem] md:h-96 hidden sm:block">
              <Link
                href={localizePath(`/jobs/${item.url_slug}`, locale)}
                key={idx}
                className={`bg-grey-100 h-full ${hoverColor ? hoverColor : "hover:bg-green"
                  } group/item text-purple py-4 md:pb-[89px] md:pt-10 flex flex-col px-5 md:px-[35px] relative ${`role-${idx}`}`}
              >
                <span className="text-2xl sm:text-3xl group-hover/item:underline">
                  {item.title}
                </span>
                <span className="text-lg md:text-xl py-4 md:py-[26px]">
                  {item.salary_package}
                </span>
                <div className="[&>p]:bg-grey-200 [&>p]:text-base [&>p]:w-max [&>p]:py-3 [&>p]:px-[26px] flex flex-wrap max-w-[350px] [&>p]:mr-[11px] [&>p]:mb-[10px]">
                  {getCategory(item)?.map((type) => (
                    <p key={type.id}>{type.name}</p>
                  ))}
                </div>
                <div className="absolute cursor-pointer hidden bottom-0 right-0 bg-purple w-12 h-12 z-10 group-hover/item:flex justify-center items-center">
                  <ArrowIcon className="fill-white" />
                </div>
              </Link>
            </div>
          </CustomSwiperSlide>
        ))}
      </CustomSwiper> */}
    </div>
  );
}
