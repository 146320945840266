// src/hooks/useJobs.js
import useSourceFlowCollection from "@/hooks/useSourceFlowCollection";
import jobsData from "@/../.sourceflow/jobs.json";

const useJobs = () => {
  const jobs = useSourceFlowCollection(jobsData.jobs);

  // Remove duplicate jobs based on the 'id' property
  const uniqueJobs = jobs.filter((job, index, self) =>
    index === self.findIndex((j) => j.id === job.id)
  );

  return uniqueJobs;
};

export default useJobs;